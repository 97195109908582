import {
  AspectRatio,
  BoxProps,
  Button,
  Grid,
  HStack,
  Img,
} from '@chakra-ui/react'
import { CsbAccountMenu, CsbLogoIcon, CsbNextLink } from '@src/components'
import { MeQuery, useMeQuery } from '@src/graphql/generated/graphql'
import { useMe } from '@src/hooks'
import { memo, useMemo } from 'react'
import { BsBoxArrowUpRight } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import { atom, useRecoilState } from 'recoil'

export const documentState = atom<
  MeQuery['documents']['collection'] | undefined
>({
  default: undefined,
  key: 'documentState',
})

type CsbHeaderPropsType = Omit<BoxProps, 'children'>

export const CsbLoginHeader = memo(({ ...props }: CsbHeaderPropsType) => {
  const [_manual, setManual] = useRecoilState(documentState)
  const { company, role, setMe } = useMe()

  const navigate = useNavigate()

  useMeQuery({
    onCompleted: (data) => {
      setMe(data.me)
      setManual(
        data.documents.collection.length !== 0
          ? data.documents.collection
          : undefined
      )
    },
    onError: () => {
      navigate('/?session=out')
    },
  })

  const manuals = useMemo(() => {
    return _manual
  }, [_manual])

  const logo = useMemo(() => {
    if (
      company === null ||
      company?.logoPath === null ||
      company?.logoPath === '' ||
      company?.logoPath === undefined
    ) {
      return <CsbLogoIcon />
    }

    return <Img src={company?.logoPath} />
  }, [company])

  //ロゴのhref先
  const logoHref = role?.admin ? '/companies' : '/dashboard'

  return (
    <Grid
      alignItems={'center'}
      as="header"
      border="solid 1px"
      borderColor="gray.200"
      h={20}
      justifyContent={'space-between'}
      px={31}
      py={3.5}
      templateColumns={'repeat(2,auto)'}
      {...props}
    >
      <CsbNextLink
        _hover={{
          opacity: 0.8,
        }}
        href={logoHref}
        transition={'opacity 0.5s ease-out'}
        w={92}
      >
        <AspectRatio ratio={299 / 127}>{logo}</AspectRatio>
      </CsbNextLink>
      <HStack spacing={3}>
        {manuals &&
          manuals.map((manual) => (
            <Button
              as={'a'}
              href={manual.url}
              key={manual.uuid}
              rightIcon={<BsBoxArrowUpRight />}
              target={'_blank'}
            >
              {manual.name}
            </Button>
          ))}
        <CsbAccountMenu />
      </HStack>
    </Grid>
  )
})
