import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Grid,
  Heading,
  HStack,
  Input,
  ModalCloseButton,
  Select,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import {
  CsbFormModal,
  CsbModal,
  CsbModalBody,
  CsbModalFooter,
  CsbModalHeader,
  CsbPagination,
  CsbTag,
} from '@src/components'
import { CsbTable } from '@src/components/CsbTable'
import { CsbDataModal } from '@src/components/Modal/CsbDataModal'
import { useCampaignsHooksResult } from '@src/components/pages/campaigns/hooks'
import {
  CampaignSearch,
  CampaignsQuery,
  RevealingRepeat,
  RevealingSend,
  RevealingTarget,
} from '@src/graphql/generated/graphql'
import { Base } from '@src/layouts'
import dayjs from 'dayjs'
import { memo, useState } from 'react'
import { Controller } from 'react-hook-form'

type RevealingPhishingEmailSchedule =
  CampaignsQuery['campaigns']['collection'][0]['revealingPhishingEmailSchedule']

export const Campaigns = memo(
  ({
    current,
    data,
    dataModal,
    errors,
    exportForm,
    exportModal,
    isExporting,
    navigate,
    onChangePage,
    onExport,
    onResetSearch,
    onSearch,
    onSwitchCompany,
    searchForm,
    switchCompanyModal,
  }: useCampaignsHooksResult) => {
    const [phishingData, setPhishingData] = useState<{
      companyName: string
      companyUuid: string
      url: string
    } | null>(null)

    const searchTypes = [
      [CampaignSearch.All, 'すべて'],
      [CampaignSearch.PhishingOnly, 'フィッシングメール'],
      [CampaignSearch.ContentsOnly, 'コンテンツメール'],
    ]

    const sendTargetMap = new Map([
      [RevealingTarget.All, '全員'],
      [RevealingTarget.Unanswered, '未回答ユーザー'],
      [RevealingTarget.NoLearningAccess, 'ラーニング未アクセスユーザー'],
      [
        RevealingTarget.NoLearningAccessAndRiskUser,
        'ラーニング未アクセスでリスクのあるユーザー',
      ],
      [RevealingTarget.RiskUser, 'リスクのあるユーザー'],
      [RevealingTarget.UnansweredAndRiskUser, '未回答のリスクのあるユーザー'],
    ])

    const onNavigate = () => {
      onSwitchCompany(phishingData?.companyUuid ?? '').then((_) => {
        navigate(phishingData?.url ?? '')
      })
    }

    const getFirstRevealingEmailData = (
      isDeliveryContentsOnly: boolean,
      schedule: RevealingPhishingEmailSchedule
    ) => {
      if (!schedule) return null

      if (
        schedule?.sendType === RevealingSend.Schedule &&
        schedule?.firstScheduleAt
      ) {
        const scheduleAt = dayjs(schedule?.firstScheduleAt).format(
          'YYYY/MM/DD HH:mm'
        )
        return `${scheduleAt}`
      } else if (schedule?.sendType === RevealingSend.DaysAfterRevealing) {
        const title = !isDeliveryContentsOnly
          ? '訓練メール配信後'
          : 'コンテンツメール配信後'
        const sendRevealingMailDays = schedule?.sendRevealingMailDays ?? 0
        const sendMailDays =
          sendRevealingMailDays === 0 ? '当日' : `${sendRevealingMailDays}日後`
        const sendMailAt = dayjs(schedule?.sendRevealingMailAt).format('HH:mm')
        return `${title} ${sendMailDays} ${sendMailAt}`
      } else {
        return '送信なし'
      }
    }

    const getRepeatRevealingEmailData = (
      schedule: RevealingPhishingEmailSchedule
    ) => {
      if (!schedule) return null

      if (schedule?.repeatType === RevealingRepeat.RepeatCount) {
        const interval = schedule?.repeatInterval ?? 0
        const count = schedule?.repeatCount ?? 0
        const message =
          `${interval}日間隔に${count}回送信` +
          (schedule?.answeredRate !== null
            ? `（回答率${schedule?.answeredRate}%以上で終了）`
            : '')
        return (
          <Text fontSize={'sm'} lineHeight={1}>
            {message}
          </Text>
        )
      } else if (schedule?.repeatType === RevealingRepeat.Dates) {
        const dates = schedule?.revealingPhishingEmailScheduleDates ?? []
        const elements = dates.slice(0, 3).map((item, index) => (
          <Text fontSize={'sm'} key={index} lineHeight={1}>
            {dayjs(item.scheduleAt).format('YYYY/MM/DD HH:mm')}
          </Text>
        ))
        return dates.length > 3 ? (
          <>
            {elements}
            <Text
              _hover={{ textDecoration: 'underline' }}
              as={'button'}
              fontSize={'xs'}
              type={'button'}
              onClick={(e) => {
                e.stopPropagation()
                dataModal.onOpen()
                dataModal.setData({
                  items: dates.map((item) => item.scheduleAt),
                  title: '種明かしメール繰り返し送信日時',
                })
              }}
            >
              …他{dates.length - 3}件
            </Text>
          </>
        ) : (
          <>{elements}</>
        )
      } else {
        return (
          <Text fontSize={'sm'} lineHeight={1}>
            指定なし
          </Text>
        )
      }
    }

    return (
      <>
        <Base
          left={<Heading>キャンペーン一覧</Heading>}
          right={
            <Button colorScheme={'teal'} onClick={exportModal.onOpen}>
              エクスポート
            </Button>
          }
        >
          <Flex mt={12}>
            <Box w={'full'}>
              <HStack maxW={1100} spacing={2}>
                <Input
                  {...searchForm.register('from')}
                  type={'date'}
                  w={285}
                  onChange={(e) => {
                    searchForm.setValue('from', e.target.value)
                    onSearch().then()
                  }}
                />
                <Text>〜</Text>
                <Input
                  {...searchForm.register('to')}
                  type={'date'}
                  w={285}
                  onChange={(e) => {
                    searchForm.setValue('to', e.target.value)
                    onSearch().then()
                  }}
                />
                <Controller
                  control={searchForm.control}
                  name={'searchType'}
                  render={({ field }) => (
                    <Select
                      w={330}
                      {...field}
                      bg={'white'}
                      onChange={(e) => {
                        searchForm.setValue('searchType', e.target.value)
                        onSearch().then()
                      }}
                    >
                      {searchTypes.map(([key, value], index) => (
                        <option key={`${key}_${index}`} value={key}>
                          {value}
                        </option>
                      ))}
                    </Select>
                  )}
                />

                <Input
                  bg={'white'}
                  type="text"
                  w={500}
                  {...searchForm.register('companyName')}
                  placeholder={'企業名を入力'}
                  onKeyDown={(e) => {
                    e.stopPropagation()
                    if (e.key.toLowerCase() !== 'enter') return
                    onSearch().then()
                  }}
                />
                <ButtonGroup>
                  <Button colorScheme={'teal'} onClick={onSearch}>
                    検索
                  </Button>
                  <Button
                    colorScheme={'gray'}
                    size={'md'}
                    onClick={onResetSearch}
                  >
                    リセット
                  </Button>
                </ButtonGroup>
              </HStack>
            </Box>
          </Flex>
          <CsbTable
            labels={[
              {
                label: 'ステータス',
              },
              {
                label: '種別',
                width: 120,
              },
              {
                label: '企業名',
                width: 300,
              },
              {
                label: 'ラベル',
                width: 320,
              },
              {
                label: '種明かし初回送信設定',
                width: 300,
              },
              {
                label: '種明かし繰り返し設定',
              },
            ]}
            wrapperProps={{ mt: 35, whiteSpace: 'nowrap' }}
          >
            {data?.campaigns.collection.map((item) => (
              <CsbTable.TableRow
                key={item.uuid}
                onClick={() => {
                  const url =
                    `/phishing/${item.uuid}/${
                      item.sentAt ? 'result' : 'edit'
                    }` +
                    (item.isDeliveryContentsOnly
                      ? '?isDeliveryContentsOnly=true'
                      : '')
                  setPhishingData({
                    companyName: item.company.name,
                    companyUuid: item.company.uuid,
                    url: url,
                  })
                  switchCompanyModal.onOpen()
                }}
              >
                <CsbTable.TableCell w={120}>
                  {item.sentAt && (
                    <CsbTag colorScheme={'teal'} size={'md'}>
                      送信済み
                    </CsbTag>
                  )}

                  {!item.sentAt && item.reserveAt && (
                    <CsbTag colorScheme={'blue'} size={'md'}>
                      予約配信
                    </CsbTag>
                  )}
                </CsbTable.TableCell>
                <CsbTable.TableCell w={120}>
                  <Text fontSize={'sm'} lineHeight={1}>
                    {!item.isDeliveryContentsOnly
                      ? 'フィッシングメール'
                      : 'コンテンツメール'}
                  </Text>
                </CsbTable.TableCell>

                <CsbTable.TableCell>
                  <Tooltip aria-label="A tooltip" label={item.company.name}>
                    <Text isTruncated fontWeight={'bold'} maxW={300}>
                      {item.company.name}
                    </Text>
                  </Tooltip>
                  <Tooltip
                    aria-label="A tooltip"
                    label={`${item.phishingSite?.name}\n${item.phishingSite?.url}`}
                    maxW={'auto'}
                    whiteSpace={'pre'}
                  >
                    <Box mt={2}>
                      <Text isTruncated fontSize={'sm'} maxW={300}>
                        {item.phishingSite?.name}
                      </Text>
                      <Text isTruncated fontSize={'sm'} maxW={300}>
                        {item.phishingSite?.url}
                      </Text>
                    </Box>
                  </Tooltip>
                </CsbTable.TableCell>

                <CsbTable.TableCell>
                  <Flex align={'start'} direction={'column'}>
                    <Text color={'gray.600'} fontSize={'xs'} lineHeight={1}>
                      {item.sentAt
                        ? `${dayjs(item.sentAt).format(
                            'YYYY/MM/DD HH:mm'
                          )}に送信済み`
                        : null}

                      {!item.sentAt && item.reserveAt
                        ? `${dayjs(item.reserveAt).format(
                            'YYYY/MM/DD HH:mm'
                          )} に送信予定`
                        : null}
                    </Text>
                    <Grid>
                      <Tooltip aria-label="A tooltip" label={item.name}>
                        <Text
                          isTruncated
                          fontWeight={'bold'}
                          lineHeight={1}
                          mt={3.5}
                        >
                          {item.name}
                        </Text>
                      </Tooltip>
                    </Grid>
                    {!item?.revealingPhishingEmailSchedule && (
                      <CsbTag colorScheme={'red'} mt={2} size={'md'}>
                        {!item.isDeliveryContentsOnly
                          ? '種明かしメール未設定'
                          : 'リマインドメール未設定'}
                      </CsbTag>
                    )}
                    {item.revealingPhishingEmailSchedule?.sendTargetType && (
                      <Text
                        color={'gray.600'}
                        fontSize={'xs'}
                        lineHeight={1}
                        mt={2}
                      >
                        {!item.isDeliveryContentsOnly
                          ? '種明かしメール設定済み'
                          : 'リマインドメール設定済み'}
                      </Text>
                    )}
                  </Flex>
                </CsbTable.TableCell>

                <CsbTable.TableCell>
                  <Text fontSize={'sm'} lineHeight={1}>
                    {getFirstRevealingEmailData(
                      !!item.isDeliveryContentsOnly,
                      item.revealingPhishingEmailSchedule
                    )}
                  </Text>
                </CsbTable.TableCell>

                <CsbTable.TableCell>
                  {item.revealingPhishingEmailSchedule && (
                    <>
                      <Text fontSize={'sm'} lineHeight={1} mb={2}>
                        送信対象:
                        {' ' +
                          sendTargetMap.get(
                            (item.revealingPhishingEmailSchedule
                              ?.sendTargetType ?? '') as RevealingTarget
                          )}
                      </Text>

                      {getRepeatRevealingEmailData(
                        item.revealingPhishingEmailSchedule
                      )}
                    </>
                  )}
                </CsbTable.TableCell>
              </CsbTable.TableRow>
            ))}
          </CsbTable>
          <Flex mt={5}>
            {data ? (
              <CsbPagination
                current={current}
                defaultCurrent={current}
                pageSize={data.campaigns.metadata.limitValue}
                total={data.campaigns.metadata.totalCount}
                onChange={onChangePage('/campaigns')}
              />
            ) : null}
          </Flex>
          <CsbDataModal {...dataModal}>
            <CsbModalHeader>{dataModal.data?.title}</CsbModalHeader>
            <CsbModalBody mt={2}>
              <VStack align={'start'} maxH={60} overflowY={'auto'}>
                {dataModal.data?.items.map((date, index) => (
                  <CsbTag key={index} size={'lg'}>
                    {dayjs(date).format('YYYY/MM/DD HH:mm')}
                  </CsbTag>
                ))}
              </VStack>
            </CsbModalBody>
          </CsbDataModal>
        </Base>

        <CsbFormModal
          header={'エクスポート確認'}
          isDisabled={exportForm.watch('from') === ''}
          isLoading={isExporting}
          isOpen={exportModal.isOpen}
          submit={'エクスポート'}
          onCancel={exportModal.onClose}
          onClose={exportModal.onClose}
          onSubmit={onExport}
        >
          <Controller
            control={exportForm.control}
            name={'searchType'}
            render={({ field }) => (
              <Select
                w={330}
                {...field}
                bg={'white'}
                onChange={(e) => {
                  exportForm.setValue(
                    'searchType',
                    e.target.value as CampaignSearch
                  )
                }}
              >
                {searchTypes
                  .filter(([key, _]) => key !== 'ALL')
                  .map(([key, value], index) => (
                    <option key={`${key}_${index}`} value={key}>
                      {value}
                    </option>
                  ))}
              </Select>
            )}
          />

          <HStack maxW={600} mb={5} spacing={2}>
            <Input
              {...exportForm.register('from')}
              type={'date'}
              w={285}
              onChange={(e) => {
                exportForm.setValue('from', e.target.value)
              }}
            />
            <Text>〜</Text>
            <Input
              {...exportForm.register('to')}
              type={'date'}
              w={285}
              onChange={(e) => {
                exportForm.setValue('to', e.target.value)
              }}
            />
          </HStack>
          {errors.length > 0 && (
            <Text color={'red'}>{errors[0]?.message ?? ''}</Text>
          )}
        </CsbFormModal>

        <CsbModal
          isOpen={switchCompanyModal.isOpen}
          onClose={switchCompanyModal.onClose}
        >
          <CsbModalHeader>確認</CsbModalHeader>
          <ModalCloseButton />
          <CsbModalBody mt={5}>
            フィシング画面に遷移しますか？ ※「{phishingData?.companyName ?? ''}
            」に切り替わります。
          </CsbModalBody>
          <CsbModalFooter mt={6}>
            <HStack spacing={3}>
              <Button colorScheme={'gray'} onClick={switchCompanyModal.onClose}>
                キャンセル
              </Button>
              <Button onClick={onNavigate}>OK</Button>
            </HStack>
          </CsbModalFooter>
        </CsbModal>
      </>
    )
  }
)
